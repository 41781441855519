<template>
  <el-menu
    :default-active="activeIndexFun"
    class="el-menu"
    mode="horizontal"
    @select="handleSelect"
  >
    <!-- <el-menu-item><router-link to="/home">首页</router-link></el-menu-item> -->
    <el-menu-item   v-for="(item,index) in arr" :key="index" :index="`${index+1}`"   @click="linkproduct(item)"
       v-log="[item.name, '点击']">{{item.name}}</el-menu-item
    >
  </el-menu>
</template>
<script>
import {getBuyOneYuanList} from  "api/activity.js";
// import {selectTagList}  from "api/home.js";
export default {
  data() {
    return {
      arr:[{name:"首页",path:"Home",num:0},
      {name:"医保用药",path:"ProductType",num:1},
      {name:"非处方药",path:"ProductType",num:2},
      {name:"高毛专区",path:"ProductType",num:3},
      {name:"特惠专区",path:"ProductType",num:4},
      // {name:"新品预售",path:"ActivityModuleList",num:5},
        // {name:"万盒拼团",path:"flashGroupList",num:5},
      // {name:"超值套餐",path:"SetMealList",num:6},
        //  {name:"一元购",path:"ONEBUYDetail",num:6},
      {name:"尾品特卖",path:"ProductType",num:7},
        {name:"保健食品",path:"ProductType",num:8},
      // {name:"积分商城",path:"ProductType",num:9},
// integralMall
        {name:"积分商城",path:"IntegralMallList",num:9},
      ],
      activeIndex: "0",
    };
  },
  methods: {
    handleSelect() {
      // key, keyPath
    },
    
// 除首页外的跳转
jumpPath(item){

  console.log(item)
            // 跳标签列表页
                this.$router.push({name:"TagIdList",query:{tagId:item.tagId,type:item.num}})
          },
    linkproduct(item){
      if(item.num ===0){
        this.$router.push({name:item.path})
      }else{

        // this.jumpPath(item)

        if(item.path=="SetMealList"){
           this.$router.push({name:item.path,query:{type:item.num}})
        }else if(item.path=="ActivityModuleList"){
             this.$router.push({name:"ActivityModuleList",query:{ActivityType:1,type:item.num}})
        }else if(item.path=="flashGroupList"){
             //限时拼团
            this.$router.push({
              name: "FlashGroupList",
            })
        }else if(item.path=="ONEBUYDetail"){
          getBuyOneYuanList().then(res=>{
           if(res.data.code==200){
               //一元购
              this.$router.push({
                name: "ONEBUYDetail",
                query: { productId: res.data.data.records[0].id, promotionId:res.data.data.records[0].promotionId },
              });
           }else{
              this.$message({
                message: '暂无一元购活动',
                type: "warning",
              });
           }
             
          })
           
        }
        else{
          this.$router.push({name:item.path,query:{type:item.num}})
        }
        if(sessionStorage.getItem("parmesData")){
          sessionStorage.removeItem("parmesData")
        }
      }
      
    }
  },
  computed: {
    activeIndexFun:function(){
      let activeindex="0"
      if(this.$route.query.type){
        activeindex=String(Number(this.$route.query.type)+1)
      }else{
        activeindex="1"
      }
      return activeindex
    }
  },
  created(){
    // selectTagList().then(
    //     res=>{
    //       this.arr=[];
    //       let menuArr=res.data.data;
    //       //  console.log('menuArr',menuArr)
    //       this.arr.push({name:"首页",path:"Home",num:0})
    //       menuArr.forEach((item,i)=> {
    //         this.arr.push({name:item.tagName,path:"tagIdList",num:i+1,tagId:item.id})
    //       });
          
    //       console.log('reeeeee',this.arr)
    //     }
    //   )
  },
  
};
</script>

<style scoped lang="less">
.el-menu a {
  text-decoration: none;
}
.el-menu--horizontal > .el-menu-item:hover{
  border-bottom: 2px solid #FFD303;
  color: #333;
  font-weight: bold;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid #FFD303;
  color: #333;
  font-weight: bold;
}
.el-menu--horizontal > .el-menu-item {
  color: #333333;
  height: 40px;
  line-height: 40px;
  
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
  background: #f6f6f6;
}
</style>
